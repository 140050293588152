import { graphql } from "gatsby";
import AuthorInfo from "../components/author-info";
import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query AuthorInfoTemplateQuery($id: String!) {
    author: sanityAuthor(id: { eq: $id }) {
      id
      name
      slug {
          current
      }
      image {
        ...SanityImage
        alt
      }
      _rawBio(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const AuthorInfoTemplate = (props) => {
  const { data, errors } = props;
  const author = data && data.author;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {author && (
        <SEO
          title={author.name || "Who dis?"}
          description={toPlainText(author._rawBio)}
          image={author.image}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {author && <AuthorInfo {...author} />}
    </Layout>
  );
};

export default AuthorInfoTemplate;
