// extracted by mini-css-extract-plugin
export const root = "author-info-module--root--3WUbF";
export const name = "author-info-module--name--1MCi1 typography-module--responsiveTitle1--2aak3";
export const image = "author-info-module--image--34ALE";
export const bio = "author-info-module--bio--2Ze_4";
export const grid = "author-info-module--grid--1dmDY";
export const mainContent = "author-info-module--mainContent--GgTkO";
export const metaContent = "author-info-module--metaContent--2uT8X";
export const publishedAt = "author-info-module--publishedAt--KahCn typography-module--small--2jpCI";
export const categories = "author-info-module--categories--GRIqf";
export const categoriesHeadline = "author-info-module--categoriesHeadline--1tyCX typography-module--responsiveTitle2--3J_AG";