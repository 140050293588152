import * as styles from "./author-info.module.css";
import Container from "./container";
import PortableText from "./portableText";
import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

function AuthorInfo(props) {
    const {
        _rawBio,
        name,
        image,
    } = props;
    return (
    <article className={styles.root}>
        <Container>
            <div className={styles.mainContent}>
                {/* image at top, name underneath, then bio under that. */}
                {image && image.asset && (
                    <div className={styles.image}>
                        <img
                        src={imageUrlFor(buildImageObj(image))
                            .width(500)
                            .height(500)
                            .fit("crop")
                            .auto("format")
                            .url()}
                        alt={image.alt}
                        />
                    </div>
                )}
                <h1 className={styles.name}>{name}</h1>
                <div className={styles.bio}>
                    {_rawBio && <PortableText blocks={_rawBio} />}
                </div>
            </div>
        </Container>
    </article>
    );
}

export default AuthorInfo;